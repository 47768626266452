import $ from 'jquery';
import 'jquery-match-height';
import 'whatwg-fetch';
import Cookies from 'js-cookie';

window.onload = function () {
	$(async function () {

		// Navigation
		$('.navbar-burger[data-target]').each(function () {
			var $this = $(this);
			var $toggles = $this.add('#' + $this.data('target'));

			$this.click(function () {
				$toggles.toggleClass('is-active');
			});
		});

		// Set same height
		$('.card-content').matchHeight({
			property: 'height'
		});

		// Slideshow
		$('.swiper-container').each(async function () {
			if ($(this).find('.swiper-slide').length <= 1) return;

			const Swiper = (await import(/* webpackChunkName: "swiper" */ './swiper.js')).default;

			new Swiper(this, {
				grabCursor: true,
				slidesPerView: 1,
				spaceBetween: 0,
				loop: true,
				autoplay: {
					delay: 3000
				},
				speed: 1500,
				pagination: {
					el: '.swiper-pagination',
					clickable: true
				}
			});
		});

		// Landing page swiper
		$('.landingpage-swiper-container').each(async function () {
			if ($(this).find('.swiper-slide').length <= 1) return;

			const Swiper = (await import(/* webpackChunkName: "swiper" */ './swiper.js')).default;

			new Swiper(this, {
				grabCursor: true,
				slidesPerView: 1,
				spaceBetween: 50,
				autoplay: {
					delay: 3000
				},
				speed: 1500,
				navigation: {
					nextEl: '.swiper-btn-next',
					prevEl: '.swiper-btn-prev',
				},
				scrollbar: {
					el: ".swiper-scrollbar",
					hide: false,
					dragSize: 120
				},
				breakpoints: {
					// when window width is >= 1024px
					1468: {
						slidesPerView: 3,
						spaceBetween: 50
					},
					850: {
						slidesPerView: 2,
						spaceBetween: 50
					}
				},
			});
		});

		// Read more experiences
		$('.read-more').each(function () {
			var $this = $(this);

			$this.click(function () {
				var slide = $this.parent().parent();
				var paragraph = slide.find('.paragraph');
				if (paragraph.hasClass('clamped')) {
					paragraph.removeClass('clamped');
				}
				if (slide.hasClass('capped')) {
					slide.removeClass('capped');
				}
				$this.parent().addClass('visuallyhidden');
			});
		});

		// Product cta
		$('.cta-button').click(function () {
			if ($('.cta-overlay').hasClass('hidden')) {
				$('.cta-overlay').removeClass('hidden');
			}
		});

		$('.cta-close').click(function () {
			if (!($('.cta-overlay').hasClass('hidden'))) {
				$('.cta-overlay').addClass('hidden');
			}
		});


		$('.dl-overlay-close').click(function () {
			if (!($('.dl-overlay').hasClass('hidden'))) {
				$('.dl-overlay').addClass('hidden');
			}
		});

		$('.dl-btn').click(function () {
			if ($('.dl-overlay').hasClass('hidden')) {
				$('.dl-overlay').removeClass('hidden');
			}
		});

		$('.dl-overlay').click(function (e) {
			var className = e.target.className;
			if (className.indexOf("dl-overlay") >= 0) {
				if (!($('.dl-overlay').hasClass('hidden'))) {
					$('.dl-overlay').addClass('hidden');
				}
			}
		});

		// Filter form
		$('form.filter').each(function () {
			var $form = $(this),
				update = $form.data('update');

			var currentRequest = null,
				sendRequest = function (url, type = 'get', data = null) {
					currentRequest = $.ajax({
						type: type,
						url: url,
						data: data,
						beforeSend: function () {
							if (currentRequest !== null) {
								currentRequest.abort();
							}

							$(update).addClass('loading');
						},
						success: async function (data) {
							var $data = $(data);
							$(update).replaceWith($data.find(update));

							if (window.history) {
								window.history.replaceState(null, null, this.url);
							}

							$('.card-content').matchHeight({
								property: 'height'
							});
						}
					});
				};

			$form.submit(function (e) {
				sendRequest($form.attr('action'), $form.attr('method'), $form.serialize().replace(/&?[^=&]+=(&|$)/g, ''));
				e.preventDefault();
			});

			$form.on('reset', function () {
				sendRequest($form.attr('action'), $form.attr('method'));
			});

			$form.find('select, input[type=checkbox], input[type=radio]').change(function () {
				// Enable auto-submit
				$form.submit();
			});

			// Hide submit
			$form.find('[type=submit]:not(.is-search), [type=reset]').toggleClass('is-hidden');
		});

		// Paged news messages
		$('.paged-news-messages').each(async function () {
			await import(/* webpackChunkName: "waypoints" */ 'waypoints/lib/jquery.waypoints.js');
			await import(/* webpackChunkName: "waypoints" */ 'waypoints/lib/shortcuts/infinite.js');

			var $this = $(this);
			$this.find('a[rel=next]').one('click', function (e) {
				new Waypoint.Infinite({
					element: $this,
					container: $this.find('.columns'),
					items: '.column.is-news-message',
					more: 'a[rel=next]',
					offset: function () {
						return Waypoint.offsetAliases['bottom-in-view'].call(this) + 200;
					},
					onAfterPageLoad: function ($items) {
						// Fade in added items
						const delay = 40;
						$items.css('opacity', 0).each(function (i) {
							var $item = $(this);
							setTimeout(function () {
								$item.css({
									opacity: 1,
									transition: 'opacity ease 120ms'
								});
							}, i * delay);
						});
					}
				});

				$this.find('.pagination-container').hide();
				e.preventDefault();
			});
		});

		// Google Maps
		$('.maps').each(async function () {
			var maps = $(this),
				apiKey = maps.data('api-key'),
				dataGeolocations = maps.data('geolocations').split(';'),
				mapStyle = maps.data('style');

			const loadGoogleMapsApi = (await import(/* webpackChunkName: "load-google-maps-api" */ 'load-google-maps-api')).default;
			const googleMaps = await loadGoogleMapsApi({
				key: apiKey
			});

			var bounds = new google.maps.LatLngBounds();

			var map = new googleMaps.Map(document.querySelector('.maps'), {
				zoom: 17,
				zoomControl: false,
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false,
				styles: mapStyle
			});

			var image = {
				url: require('../images/pointer.png').default,
				scaledSize: new google.maps.Size(74, 88),
				origin: new google.maps.Point(0, 0),
				anchor: new google.maps.Point(37, 88)
			};

			for (var i = 0; i < dataGeolocations.length; i++) {
				var latLng = dataGeolocations[i].split(',');

				var marker = new google.maps.Marker({
					position: {
						lat: parseFloat(latLng[0]),
						lng: parseFloat(latLng[1])
					},
					map: map,
					icon: image
				});

				bounds.extend(marker.position);
			}

			// Set zoom bounds are fit
			var zoomChangeBoundsListener;
			google.maps.event.addListener(map, 'zoom_changed', function () {
				zoomChangeBoundsListener =
					google.maps.event.addListener(map, 'bounds_changed', function (event) {
						if (this.getZoom() > 15 && this.initialZoom === true) {
							this.setZoom(8);
							this.initialZoom = false;
						}
						google.maps.event.removeListener(zoomChangeBoundsListener);
					});
			});

			map.initialZoom = true;
			map.fitBounds(bounds);
		});

		// Tabs
		$('.tabs-container').each(function () {
			var $this = $(this);
			var $tabs = $this.find('.tabs li');
			var $togglers = $tabs.children('a[href]');
			var $contents = $togglers.map(function () {
				var id = $(this).attr('href');

				return $(id).get();
			});

			$togglers.each(function (i) {
				var $toggler = $(this);
				var $tab = $($tabs[i]);
				var $content = $($contents[i]);

				$toggler.click(function (e) {
					$tabs.removeClass('is-active');
					$contents.addClass('is-hidden');
					$tab.addClass('is-active');
					$content.removeClass('is-hidden');

					$toggler.blur();
					e.preventDefault();
				});
			});
		});

		// Dropdown
		$('.dropdown').each(function () {
			var dropdown = $(this),
				content = dropdown.find('.dropdown-content'),
				menu = dropdown.find('.dropdown-menu'),
				trigger = dropdown.find('.dropdown-trigger');

			trigger.on('click', function (e) {
				e.preventDefault();

				if (dropdown.hasClass('is-active')) {
					dropdown.removeClass('is-active');
					menu.height(0);
				}
				else {
					dropdown.addClass('is-active');
					menu.height(content.outerHeight());
				}
			});
		});

		// Jump to submitted form
		$('.section:has(form)').each(function () {
			var $this = $(this),
				$form = $this.find('form'),
				anchor = $this.find('.anchor').attr('name');

			$form.submit(function () {
				if (!$form[0].classList.contains("filter")) {
					$button.prop('disabled', true);
					$button.addClass('is-loading');
				}
			});
		});

		// Lightbox
		$('body:has([rel=lightbox])').each(async function () {
			await import(/* webpackChunkName: "lightgallery" */ 'lightgallery');
			await import(/* webpackChunkName: "lightgallery" */ 'lg-video');

			$(this).lightGallery({
				selector: '[rel=lightbox]',
				download: false,
				thumbnail: false,
				fullScreen: false,
				actualSize: false,
				hash: false,
				videoMaxWidth: '1440px'
			});
		});

		// Toastr
		window.fetch('/umbraco/surface/Toaster/GetToastrMessages')
			.then(res => res.json())
			.then(async json => {
				var toastr = await import('toastr');
				var toastrCount = json.length;

				var cookieName = 'toast',
					ignoredIds = Cookies.get(cookieName),
					ignoreId = function (id) {
						if (ignoredIds) ignoredIds += ',' + id;
						else ignoredIds = id;

						Cookies.set(cookieName, ignoredIds);
					};

				if (toastrCount > 0) {
					for (var i = 0; i < toastrCount; i++) {
						(function () {
							var toastrMessage = json[i];

							if (!ignoredIds || !ignoredIds.includes(toastrMessage.id)) {
								//Set options
								toastr.options.progressBar = true;
								toastr.options.closeButton = true;
								toastr.options.timeOut = 5000;
								toastr.options.extendedTimeOut = 5000;
								toastr.options.showDuration = 0;
								toastr.options.target = 'header';
								toastr.options.titleClass = '';
								toastr.options.messageClass = 'date';

								toastr.info('', '<a href=' + toastrMessage.url + ' data-id="' + toastrMessage.id + '"><strong>' + toastrMessage.title + '</strong><span>' + toastrMessage.text + '</span></a>', {
									onHidden: function () {
										ignoreId(toastrMessage.id);
									}
								}).on({
									click: function (e) {
										e.preventDefault();
										ignoreId(toastrMessage.id);
										document.location = toastrMessage.url;
									}
								});
							}
						})();
					}
				}
			});

		// Search bar large screen
		$('.navbar .navbar-item.is-search').each(function () {
			var element = $(this);
			var searchBar = $('.navbar-menu .search-bar');
			var navbar = $('.navbar-end');

			if (searchBar !== null) {
				element.on('click', function (e) {
					e.preventDefault();

					var top = element.offset().top - 7 + 'px';
					var left = element.offset().left - 10 + 'px';
					var width = navbar.width() - (element.offset().left - navbar.offset().left) + 'px';

					searchBar.css({ top: top, left: left, width: width });
					searchBar.addClass('is-visible');
				});

				searchBar.find('.is-close').on('click', function (e) {
					e.preventDefault();
					searchBar.css({ width: 56 + 'px' });
					searchBar.removeClass('is-visible');
				});
			}
		});

		// Search bar small screen
		$('.search-bar.small-screen').each(function () {
			var element = $(this);
			var breadcrumbs = $('.breadcrumb');
			var top = (122 + breadcrumbs.height()) + 'px';

			element.css({ top: top });

			var buttonClose = element.find('.button.is-close');
			buttonClose.removeClass('is-close');
			buttonClose.addClass('is-search');

			buttonClose.on('click', function () {
				element.toggleClass('is-open');
				buttonClose.toggleClass('is-close');
				buttonClose.toggleClass('is-search');
			});
		});

		// Check open forms on dropdown click
		var labelText = $('.dropdown-label').html();
		$('.form-checkbox').change(
			function () {
				var element = $(this);
				if (element.is(':checked')) {
					var checkBoxes = $('.form-checkbox');
					checkBoxes.each(function () {
						var checkbox = $(this);
						if (checkbox.is(':checked') && checkbox[0] !== element[0]) {
							checkbox.prop('checked', false);
						}
						else if (checkbox.is(':checked') && checkbox[0] === element[0]) {
							$('.dropdown-label').text($('label[for="' + checkbox[0].id + '"]').html());
						}
					});
				}
				else {
					$('.dropdown-label').text(labelText);
				}
			});
	});

	// Close dropdowns on escape key
	var dropdowns = document.querySelectorAll('.dropdown-checkbox').forEach(function (dropdown) {
		if (dropdown) {

			const toggle = checked => {
				dropdown.checked = checked;
			};

			document.addEventListener('keydown', e => {
				if (e.keyCode === 27 && dropdown.checked) {
					toggle(false);
				}
			});

			document.addEventListener('click', e => {
				if (dropdown.checked && e.target !== dropdown && !(e.target.classList.contains("dropdown-label"))) {
					toggle(false);
				}
			});

			// Keep aria-expanded in-sync with checked state
			dropdown.addEventListener('change', () => {
				toggle(dropdown.checked);
			});

			toggle(dropdown.checked);
		}
	});

	// Jump to submitted form
	document.querySelectorAll('.website-form').forEach(el => {
		var form = el.querySelector('form'),
			button = el.querySelector('.button');

		if (form) {

			form.addEventListener('submit', function () {
				button.classList.add('form-loading');
			});
		}
	});
}